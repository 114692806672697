<template>
    <div ref="modal" class="c-modal-popup" :class="{ 'is-modal-fullscreen': isFullScreen }">
        <QuesoScrollable class="c-modal-popup__inner" shadows>
            <slot></slot>
        </QuesoScrollable>
    </div>
</template>

<script setup>
import { computed, ref, onBeforeUnmount } from "vue";
import { useResizeObserver, useWindowSize } from "@vueuse/core";
import { ANIMATION_DURATIONS } from "@/constants";
import { QuesoScrollable } from "@allomambo/queso";

const modal = ref(null);
const modalGutter = ref(20);
const modalScrollHeight = ref(0);
const { height: windowHeight } = useWindowSize();

// Add debounce to prevent rapid firing
let resizeTimeout;
const handleResize = ([entry]) => {
    if (resizeTimeout) {
        window.cancelAnimationFrame(resizeTimeout);
    }

    resizeTimeout = window.requestAnimationFrame(() => {
        modalScrollHeight.value = entry.target.scrollHeight;
    });
};

const observer = useResizeObserver(modal, handleResize);

// Cleanup
onBeforeUnmount(() => {
    if (resizeTimeout) {
        window.cancelAnimationFrame(resizeTimeout);
    }
    observer.stop();
});

const isFullScreen = computed(() => modalScrollHeight.value >= windowHeight.value - modalGutter.value * 2);

const visual = {
    gutterX: `${modalGutter.value}px`,
    transitionIn: `${ANIMATION_DURATIONS.popup.in}s`,
    transitionOut: `${ANIMATION_DURATIONS.popup.out}s`,
};
</script>

<style lang="scss">
.c-modal-popup {
    --modal-popup-width-default: 95rem;
    --modal-popup-min-height-default: 0rem;
    --modal-popup-height-default: 71rem;
    --modal-popup-max-height-default: calc(100% - v-bind("visual.gutterX") * 2);
    --modal-popup-padding-default: var(--grid-gap);
    --modal-popup-btn-offset-default: var(--grid-gap-half);
    --modal-popup-gutter-default: var(--grid-gap-quarter);
    --modal-popup-bg-color-default: var(--color-white);
    --modal-popup-radius-default: var(--radius-regular);
    --modal-popup-translateY-default: 2rem;
    --modal-popup-transition-duration: v-bind("visual.transitionOut");

    width: dvar(modal-popup-width);
    max-width: calc(100% - var(--modal-popup-gutter-x, dvar(modal-popup-gutter)) * 2);
    height: dvar(modal-popup-height);
    min-height: dvar(modal-popup-min-height);
    overflow: hidden;
    opacity: var(--modal-popup-opacity, 0);
    transform: translateY(dvar(modal-popup-translateY));
    background-color: dvar(modal-popup-bg-color);
    border-radius: dvar(modal-popup-radius);
    transition: opacity var(--modal-popup-transition-duration) ease,
        transform var(--modal-popup-transition-duration) $in-out-sine;

    @include min(md) {
        --modal-popup-btn-offset-default: var(--grid-gap);
        --modal-popup-gutter-default: var(--grid-gap);
        --modal-popup-radius-default: var(--radius-large);
    }

    &.is-modal-fullscreen {
        height: dvar(modal-popup-max-height);
    }

    &__inner {
        --queso-scrollable-height: 100%;
        --overflow-shadow-size: 9rem;

        &:before,
        &:after {
            transition: opacity 0.4s ease;
        }

        .queso-scrollable__content {
            padding: dvar(modal-popup-padding);
        }
    }

    .c-modal-close-button {
        position: absolute;
        top: dvar(modal-popup-btn-offset);
        right: dvar(modal-popup-btn-offset);
        z-index: 9;
    }

    // OPENED
    .c-modal.is-open & {
        --modal-popup-opacity: 1;
        --modal-popup-translateY-default: 0;
        --modal-popup-transition-duration: v-bind("visual.transitionIn");
    }
}
</style>
