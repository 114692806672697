import { ref } from "vue";

// Global state to ensure single instance
let isInitialized = false;
let globalSearchParams = ref(null);
let globalSearchParamsInitial = window.location.search.slice(1);
let globalSearchParamsPrev = ref(null);

export function useFacetsHistory(renderPageCallback) {
    const onHistoryChange = () => {
        const currentSearchParams = window.location.search.slice(1);
        globalSearchParams.value = currentSearchParams || globalSearchParamsInitial;

        if (globalSearchParams.value === globalSearchParamsPrev.value) return;
        renderPageCallback(null, false);
    };

    const initializeHistory = () => {
        if (!isInitialized) {
            window.addEventListener("popstate", onHistoryChange);
            isInitialized = true;

            // Cleanup on window unload
            window.addEventListener("unload", () => {
                window.removeEventListener("popstate", onHistoryChange);
                isInitialized = false;
            });
        }
    };

    return {
        searchParams: globalSearchParams,
        searchParamsInitial: globalSearchParamsInitial,
        searchParamsPrev: globalSearchParamsPrev,
        initializeHistory,
    };
}
