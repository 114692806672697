<template>
    <flicking
        ref="carousel"
        :options="options"
        :viewportTag="viewportTag"
        :cameraTag="cameraTag"
        :cameraClass="cameraClass"
        :plugins="plugins"
        class="c-carousel-base"
        @ready="(e) => updateVariables(e)"
        :class="{
            'is-last-panel-visible': isLastPanelVisible,
            'is-loaded': isLoaded,
            'is-dragging': isDragging,
        }"
    >
        <slot></slot>

        <template v-if="hasArrow" #viewport>
            <div class="c-carousel-base__arrows">
                <slot name="arrowPrev" :cssClass="prevArrowClass">
                    <base-button :class="prevArrowClass">{{ $t("action.previous") }}</base-button>
                </slot>
                <slot name="arrowNext" :cssClass="nextArrowClass">
                    <base-button :class="nextArrowClass">{{ $t("action.next") }}</base-button>
                </slot>
            </div>
        </template>
    </flicking>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import Flicking from "@egjs/vue3-flicking";
import { Arrow, AutoPlay } from "@egjs/flicking-plugins";

import BaseButton from "../BaseButton";

const props = defineProps({
    options: {
        type: Object,
        required: false,
        default: () => {},
    },
    autoplay: {
        type: Boolean,
        required: false,
        default: false,
    },
    cameraClass: {
        type: String,
        required: false,
        default: null,
    },
    cameraTag: {
        type: String,
        required: false,
        default: "div",
    },
    hasArrow: {
        type: Boolean,
        required: false,
        default: false,
    },
    viewportTag: {
        type: String,
        required: false,
        default: "div",
    },
});

// Refs
const isLoaded = ref(false);
const carousel = ref(null);
const panelCount = ref(0);
const visiblePanels = ref([]);
const isLastPanelVisible = ref(false);
const isDragging = ref(false);

// Functions
const updateVariables = (e) => {
    panelCount.value = carousel.value.panelCount;
    visiblePanels.value = carousel.value.visiblePanels;
    isLastPanelVisible.value = checkIfLastPanelIsVisible();
};

const checkIfLastPanelIsVisible = () => {
    const found = visiblePanels?.value?.find((v) => v._index === panelCount.value - 1);
    return found !== undefined;
};

const handleDragStart = (e) => {
    isDragging.value = true;
};

const handleDragEnd = (e) => {
    setTimeout(() => {
        isDragging.value = false;
    }, 50);
};

// Plugins
const plugins = [];

// Autoplay
if (props.autoplay) {
    if (props.autoplay === "boolean" && props.autoplay === true) {
        plugins.push(new AutoPlay({ duration: 2000, stopOnHover: true }));
    } else if (props.autoplay === "object") {
        plugins.push(new AutoPlay(props.autoplay));
    }
}

// Arrows
const prevArrowClass = "c-carousel-base__arrow -prev";
const nextArrowClass = "c-carousel-base__arrow -next";

const stringToCssSelector = (cssClass) => {
    return `.${cssClass.replaceAll(" ", ".")}`;
};

if (props.hasArrow) {
    plugins.push(
        new Arrow({
            prevElSelector: stringToCssSelector(prevArrowClass),
            nextElSelector: stringToCssSelector(nextArrowClass),
            disabledClass: "is-disabled",
            moveByViewportSize: true,
        })
    );
}

// Flicking throw "FlickingError: Position "NaN" is not reachable." if not reinstantiated.
// All event listeners are added to the Flicking instance instead of the template.
watchEffect(() => {
    if (carousel.value) {
        setTimeout(() => {
            carousel.value.destroy();
        }, 1);
        setTimeout(() => {
            carousel.value.init();
            isLoaded.value = true;

            carousel.value.on("moveStart", handleDragStart);
            carousel.value.on("moveEnd", handleDragEnd);
            carousel.value.on("move", updateVariables);
        }, 2);
    }
});
</script>

<style lang="scss">
@import url("@egjs/vue3-flicking/dist/flicking.css");

.c-carousel-base {
    --carouse-base-arrows-top-default: auto;
    --carouse-base-arrows-left-default: var(--grid-gap-2X);
    --carouse-base-arrows-right-default: auto;
    --carouse-base-arrows-bottom-default: var(--grid-gap-2X);
    --carouse-base-arrows-button-width-default: 3.5rem;

    .flicking-camera {
        & > * {
            width: var(--carousel-base-slide-width, 100%);
            margin-left: var(--carousel-base-margin-left, 0);
            margin-right: var(--carousel-base-margin-right, var(--grid-gap));
        }
    }

    &__arrows {
        position: absolute;
        top: dvar(carouse-base-arrows-top);
        left: dvar(carouse-base-arrows-left);
        right: dvar(carouse-base-arrows-right);
        bottom: dvar(carouse-base-arrows-bottom);
        z-index: 2;

        background-color: var(--color-white);
        border-radius: 2rem;
        overflow: hidden;
    }

    &__arrow {
        width: dvar(carouse-base-arrows-button-width);
        padding: 1.2rem;
        background-color: transparent;
        transition: opacity 0.3s ease, background-color 0.3s ease;

        &.-prev {
            padding-right: 0.4rem;
        }
        &.-next {
            padding-left: 0.4rem;
        }

        /* Variants */
        &:hover {
            background-color: var(--color-grey-light);
        }
        &.is-disabled {
            @include unselectable;
            opacity: 0.25;
        }
    }

    // Loading
    &.is-loaded {
        --carousel-base-opacity: 1;
    }

    &.is-dragging {
        .flicking-camera > * {
            pointer-events: none;
            cursor: grab;

            a {
                pointer-events: none;
            }
        }
    }

    // Add this to prevent text selection during drag
    .flicking-camera > * {
        user-select: none;
        -webkit-user-select: none;
    }
}
</style>
