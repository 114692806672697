<template>
    <toast-base v-if="product" :name="name" class="c-toast-add-to-cart">
        <div class="c-toast-add-to-cart__inner">
            <div class="c-card-compact type-product">
                <div v-if="featuredImage" class="c-card-compact__image">
                    <img
                        :src="`${image(featuredImage, 60)}`"
                        :srcset="`${image(featuredImage, 60)} 1x, ${image(featuredImage, 120)} 2x`"
                        :alt="featuredImage.alt"
                        loading="lazy"
                        width="60"
                        height="60"
                    />
                </div>

                <div class="c-card-compact__content">
                    <h3 class="c-card-compact__heading">
                        <a :href="product.url" class="t-h7">{{ getCleanProductTitle(product.title) }}</a>
                    </h3>

                    <div class="c-card-compact__content__meta">
                        <div class="c-card-compact__options">
                            <span
                                v-if="variant.option1"
                                class="c-card-compact__options__item"
                                v-html="variant.option1"
                            />
                            <span
                                v-if="variant.option2"
                                class="c-card-compact__options__item"
                                v-html="variant.option2"
                            />
                            <span
                                v-if="variant.option3"
                                class="c-card-compact__options__item"
                                v-html="variant.option3"
                            />
                        </div>

                        <div class="c-card-compact__price">
                            <price-component :variant="variant" />
                        </div>
                    </div>
                </div>

                <div class="c-card-compact__slot -after">
                    <add-to-cart :quantity="1" :variant="variant" :is-full="false" class="-size-small" />
                </div>
            </div>
        </div>
    </toast-base>
</template>

<script setup>
import { computed, toRefs } from "vue";

import { getCleanProductTitle } from "@/utils";

import ToastBase from "@vue-components/ToastBase";
import PriceComponent from "@vue-components/PriceComponent";

const props = defineProps({
    name: {
        type: String,
        required: true,
        default: "",
    },
    product: {
        type: Object,
        required: false,
    },
    variant: {
        type: Object,
        required: false,
    },
});

// Refs
const { product, variant } = toRefs(props);

// Computed
const featuredImage = computed(() => variant?.value?.featured_image || product?.value?.featured_image);

// Shortcut
const image = (media, size) => {
    return `${media}&width=${size}&height=${size}&crop=center`;
};
</script>

<style lang="scss">
.c-toast-add-to-cart {
    --card-compact-width: 100%;
    --card-compact-max-width: none;

    min-width: max-content;

    @include max(sm) {
        width: calc(100vw - var(--grid-gap-2X));
        min-width: auto;
    }

    &__inner {
        display: flex;
        align-items: center;
        gap: var(--grid-gap-half);
        width: 100%;
    }

    .c-card-compact {
        flex-wrap: wrap;

        .c-card-compact__content {
            flex-direction: column;
            align-items: start;

            &__meta {
                display: flex;
                align-items: baseline;
                gap: var(--grid-gap-half);
            }
        }

        .c-card-compact__heading {
            flex-basis: auto;
            & > * {
                display: inline-block;
                max-width: 16ch;
            }
        }

        .c-card-compact__options {
            min-width: max-content;
        }

        .c-card-compact__price {
            --price-item-font-color: var(--color-accent-blue);
            --price-item-font-size: var(--fs-small);
            --price-item-font-weight: 600;
            --price-item-line-height: 1;
        }

        .c-card-compact__slot {
            &.-after {
                @include max(sm) {
                    flex-basis: 100%;

                    .c-button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
