<template>
    <form ref="formElement" class="c-facets-navigation-form | l-stack -vertical" @submit.prevent="onSubmitHandler()">
        <div class="c-facets-navigation-form__inner">
            <slot />
        </div>
    </form>
</template>

<script setup>
import barba from "@barba/core";

import { onMounted, ref, watchEffect } from "vue";
import { debounce } from "@utils";
import { useFacetsHistory } from "@vue-composables";

const productGridContainerId = "ProductGridContainer";
const productGridId = "product-grid";

// Refs
const formElement = ref(null);
const { searchParams, searchParamsInitial, searchParamsPrev, initializeHistory } = useFacetsHistory(renderPage);
const filterData = ref([]);

function createSearchParams(form) {
    const formData = new FormData(form);
    return new URLSearchParams(formData).toString();
}

function renderPage(event, isURLHashToBeUpdated = true) {
    searchParamsPrev.value = searchParams.value;
    const sections = getSections();

    if (sections.length === 0) {
        console.warn("No sections found");
    }

    document.getElementById(productGridContainerId)?.classList.add("is-loading");

    sections.forEach((section) => {
        const url = `${window.location.pathname}?section_id=${section.section}&${searchParams.value}`;
        const filterDataUrl = (element) => element.url === url;

        filterData.value.some(filterDataUrl)
            ? renderSectionFromCache(filterDataUrl, event)
            : renderSectionFromFetch(url, event);
    });

    //
    emitUpdate();

    if (isURLHashToBeUpdated) updateURLHash();
}

function renderSectionFromFetch(url, event) {
    fetch(url)
        .then((response) => response.text())
        .then((responseText) => {
            const html = responseText;

            filterData.value = [filterData.value, { html, url }];
            renderProductGridContainer(html);
        });
}

function renderSectionFromCache(filterDataUrl, event) {
    const html = filterData.value.find(filterDataUrl).html;
    renderProductGridContainer(html);
}

function renderProductGridContainer(html) {
    const productGridContainer = document.getElementById(productGridContainerId);
    productGridContainer.innerHTML = new DOMParser()
        .parseFromString(html, "text/html")
        .getElementById(productGridContainerId).innerHTML;
    productGridContainer.classList.remove("is-loading");
}

function getSections() {
    const productGrid = document.getElementById(productGridId);
    if (!productGrid) {
        console.warn(`Element with id '${productGridId}' not found`);
        return [];
    }
    return [
        {
            section: productGrid.dataset.id,
        },
    ];
}

function getHashedUrl() {
    // Avoid return `undefined` url
    if (!searchParams?.value) return window.location.pathname;

    return `${window.location.pathname}${searchParams?.value && "?".concat(searchParams.value)}`;
}

function emitUpdate() {
    const newUrl = getHashedUrl();
    emit("update", newUrl);
}

function updateURLHash() {
    const newUrl = getHashedUrl();
    //history.pushState(null, "", newUrl);
    // barba.go(newUrl, "filters");
    barba.history.add(newUrl, "filters");
}

function onSubmitForm(event) {
    renderPage(event);
}

function onSubmitHandler(event) {
    searchParams.value = createSearchParams(event.target.closest("form"));
    onSubmitForm(event);
}

function clear() {
    // Destructure the URL and keep the `sort_by` param
    const urlParams = new URLSearchParams(window.location.search);
    const sort_by = urlParams.get("sort_by");

    // Override searchParams before re-rendering the page
    searchParams.value = sort_by ? `sort_by=${sort_by}` : "";
    renderPage(null);
}

// Events
onMounted(() => {
    // Set searchParams based on the actual URL's search parameter
    searchParams.value = window.location.search.slice(1) || searchParamsInitial;
    initializeHistory();
});

watchEffect(() => {
    if (formElement.value) {
        formElement.value.addEventListener("input", debouncedOnSubmit.bind(this));
    }
});

const debouncedOnSubmit = debounce((event) => {
    onSubmitHandler(event);
}, 500);

const emit = defineEmits(["update"]);

defineExpose({
    clear,
});
</script>

<style lang="scss" scoped></style>
