<template>
    <div ref="facets" class="c-facets-navigation">
        <facets-navigation-form
            ref="formComponent"
            v-if="filters"
            @update="(newUrlFromFacets) => (urlFromFacets = newUrlFromFacets)"
            class="c-facets-navigation__form"
        >
            <div class="c-facets-navigation__inner">
                <facets-navigation-filters
                    v-if="filters && enableFiltering"
                    :filters="filters"
                    @clear="clearForm"
                    class="c-facets-navigation__filters"
                >
                    <template #filter-button-icon>
                        <slot name="icon-small" />
                    </template>

                    <template #modal-header-icon>
                        <slot name="icon" />
                    </template>
                </facets-navigation-filters>

                <facets-navigation-sort-dropdown
                    v-if="sortOptions && enableSorting"
                    :selected-value="sortBy"
                    :options="sortOptions"
                    class="c-facets-navigation__sort"
                />
            </div>
        </facets-navigation-form>

        <facets-navigation-count
            v-if="productsCount || allProductsCount"
            :count="productsCount"
            :total="allProductsCount"
            class="c-facets-navigation__count"
        />
    </div>
</template>

<script setup>
import { computed, onMounted, provide, ref, toRefs } from "vue";
import { useTranslationDescriptions, useSearchProducts, useScrollTo } from "@vue-composables";

import FacetsNavigationCount from "./components/FacetsNavigationCount";
import FacetsNavigationForm from "./components/FacetsNavigationForm";
import FacetsNavigationSortDropdown from "./components/FacetsNavigationSortDropdown";
import FacetsNavigationFilters from "./components/FacetsNavigationFilters";

// Props
const props = defineProps({
    enableFiltering: {
        type: Boolean,
        default: false,
    },
    enableSorting: {
        type: Boolean,
        default: false,
    },
});

// Refs
const facets = ref(null);
const formComponent = ref(null);
const urlFromFacets = ref(null);
const { enableFiltering, enableSorting } = toRefs(props);

// Computed
const urlToQuery = computed(() => urlFromFacets.value || window.location.href);

// Composables
const { activeValuesCount, allProductsCount, productsCount, sortBy, sortOptions, filters } =
    useSearchProducts(urlToQuery);
const { getDescription } = useTranslationDescriptions();

// Fn
function clearForm() {
    formComponent.value.clear();
}

// Provide
provide("activeValuesCount", activeValuesCount);

onMounted(() => {
    const urlObject = new URL(urlToQuery.value);

    if (urlObject.search.includes("filter") && facets.value) {
        setTimeout(() => useScrollTo(facets), 200);
    }
});
</script>

<style lang="scss" scoped>
.c-facets-navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: var(--grid-gap-three-quarter);

    &__inner {
        --button-border-color: var(--color-grey);
        --button-border-width: 1px;
        display: flex;
        align-items: center;
        gap: var(--grid-gap-three-quarter);
    }

    @include max(xs) {
        .c-facets-navigation__count {
            flex-basis: 100%;
            padding: 0 1.35rem;
        }
    }
}
</style>
